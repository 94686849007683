import React from "react";
import { useAuthDatas } from "../auth/JWTAuthProvider";
import useRouteGenerator from "../shared/utils/routeGenerator";
import { Navigate, useLocation } from "react-router-dom";
import Loading from "./components/Loading";
import AdminLayout from "./AdminLayout";
import MainLayout from "./MainLayout";
import { authRoutes } from "../pages/auth";
import { mainRoutes } from "../pages/main";
import { errorRoutes } from "../pages/error";
import { adminRoutes } from "../pages/admin";

function AppLayout() {
  const { isAuthenticated, isLoading, user } = useAuthDatas();

  const { pathname } = useLocation();
  const isAdminLayout = pathname.startsWith("/admin");

  const isMainLayout =
    pathname.startsWith("/main") || pathname.startsWith("/error");

  const routes = useRouteGenerator(
    isAuthenticated
      ? [
          ...errorRoutes,
          ...mainRoutes,
          ...(user?.role === "manager" ? [] : adminRoutes),
          ...authRoutes.map((route) => ({
            path: route.path,
            element: <Navigate to={"/main/catalogs"}></Navigate>,
          })),
          {
            path: "/",
            element: <Navigate to="/main/catalogs" />,
          },
          {
            path: "*",
            element: <Navigate to="/error/not-found" />,
          },
        ]
      : [
          ...errorRoutes,
          ...authRoutes,
          ...mainRoutes.concat(adminRoutes).map((route) => ({
            path: route.path,
            element: <Navigate to={"/auth/login"}></Navigate>,
          })),
          {
            path: "/",
            element: <Navigate to="/auth/login" />,
          },
          {
            path: "*",
            element: <Navigate to="/error/not-found" />,
          },
        ]
  );

  if (isLoading) {
    return <Loading></Loading>;
  }

  if (!isAuthenticated) {
    return <div> {routes}</div>;
  }

  return isAdminLayout ? (
    <AdminLayout>{routes}</AdminLayout>
  ) : isMainLayout ? (
    <MainLayout>{routes}</MainLayout>
  ) : (
    <div> {routes}</div>
  );
}

export default AppLayout;
