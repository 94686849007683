import React from "react";
import styles from "./index.module.scss";
import { useQuery } from "react-query";
import jwtAxios from "../../../auth/jwt-api";
import { API_URL } from "../../../shared/constants";
import { Button, Image, Spin } from "antd";
import { Link, useLocation } from "react-router-dom";
import { useUrlSearchParams } from "use-url-search-params";

export default function Catalogs() {
  const [urlSearchParams, setUrlSearchParams] = useUrlSearchParams({
    roomCount: "all",
  });

  const location = useLocation()
  const { data: catalogs, isLoading } = useQuery("catalogs", () =>
    jwtAxios("/catalog?limit=100&order=start_floor&sortBy=ASC")
  );

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <h1>Kataloglar</h1>
        <div>
          {[1, 2, 3, 4, 5].map((count) => (
            <Button
              onClick={() =>
                setUrlSearchParams({
                  ...urlSearchParams,
                  roomCount: urlSearchParams.roomCount == count ? "all" : count,
                })
              }
              type="primary"
              ghost={urlSearchParams.roomCount != count}
            >
              {count} Xona
            </Button>
          ))}
        </div>
      </div>
      {isLoading ? (
        <Spin
          spinning
          style={{
            width: "100%",
            // height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        ></Spin>
      ) : (
        <div className={styles.images}>
          {catalogs?.data?.data?.results?.rows
            ?.filter(
              (catalog) =>
                urlSearchParams.roomCount === "all" ||
                catalog.room_count == urlSearchParams.roomCount
            )
            ?.map((catalog) => (
              <div className={styles.card}>
                <Image
                  src={`${API_URL}/catalog_images/${catalog.image_url}`}
                  alt=""
                />
                <Link to="">
                  {" "}
                  <h2>{catalog.name}</h2>
                </Link>
              </div>
            ))}
        </div>
      )}
    </div>
  );
}
