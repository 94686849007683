import { UserOutlined } from "@ant-design/icons";
import { FaDrum } from "react-icons/fa";
import { GrUserAdmin } from "react-icons/gr";
import { IoFastFoodOutline, IoRestaurantOutline } from "react-icons/io5";
import { MdOutlineMenuBook } from "react-icons/md";
import { TbReport } from "react-icons/tb";

const menuItems = [
  {
    key: "user",
    icon: <GrUserAdmin />,
    link: "admin/table?pageId=user",
    messageId: "Foydalanuvchilar",
  },
  {
    key: "action",
    icon: <GrUserAdmin />,
    link: "admin/table?pageId=action",
    messageId: "Amallar",
    roles: ["superadmin"],
  },

  {
    key: "catalogs",
    icon: <TbReport />,
    link: "main/catalogs",
    messageId: "Kataloglar",
  },
];

export default menuItems;
