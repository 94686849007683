import React, { useState } from "react";
import Schema from "./Schema";
import image from "../../../assets/imperium/3d.png";
import styles from "./index.module.scss";
import { Popover } from "antd";

export default function BlockSelector() {
 
  return (
    <div
      style={{}}
      className={styles.blockSelector}
    >
  
      <Schema></Schema>
    </div>
  );
}
