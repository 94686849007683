// Platform uses module scss in order to not interupt other peoples code use module based styling
// If you want to add global styles you can write it inside styles file and import it to the index.scss

import { BrowserRouter } from "react-router-dom";
import JWTAuthAuthProvider from "./auth/JWTAuthProvider";
import { ConfigProvider } from "antd";
import { QueryClient, QueryClientProvider } from "react-query";
import AppLayout from "./layouts/index";
import { LanguageProvider, LOCALES } from "./i18n";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <LanguageProvider defaultLocale={LOCALES.UZBEK}>
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: "#1f6fdf",
              },
            }}
          >
            <JWTAuthAuthProvider>
              <AppLayout />
            </JWTAuthAuthProvider>
          </ConfigProvider>
        </LanguageProvider>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
