const menuItems = [
  {
    key: "catalogs",
    link: "/main/catalogs",
    messageId: "Kataloglar",
  },

  {
    key: "block-selector",
    link: "/main/block-selector",
    messageId: "Bloklar",
  },
  {
    key: "floor-selector",
    link: "/main/floor-selector",
    messageId: "Qavatlar",
  },
  {
    key: "schema",
    link: "/main/schema",
    messageId: "Sxema",
  },
  {
    key: "admin",
    link: "admin/table?pageId=user",
    messageId: "Admin",
    roles: ["manager"],
  },
];

export default menuItems;
