import React, { useState } from "react";
import { Button, Layout, Menu, theme } from "antd";
 import "./index.scss";
import MainNavbar from "./components/MainNavbar";

const { Header, Sider, Content } = Layout;
const MainLayout = ({ children }) => {
  return (
    <Layout style={{ height: "100vh" }}>
      <MainNavbar />
      <Content
        style={{
          overflow: "auto",
        }}
      >
        <div
          style={{
            minWidth: 280,
           }}
        >
          {children}
        </div>
      </Content>
    </Layout>
  );
};
export default MainLayout;
