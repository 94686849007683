import React from 'react'
import styles from "./index.module.scss"
// import image from "../../../assets/izumrud/logo.png";
import { Spin } from 'antd';
 
function Loading({ style }) {
  return (
    <div className={styles.loading} style={style}>
      <img src={"/logo.png"} alt="" />
      <Spin size="large"></Spin>
    </div>
  );
}

export default Loading