import React, { useRef, useState } from "react";
import { useUrlSearchParams } from "use-url-search-params";
import getUrl from "../../../shared/utils/getUrl";
import { Button, Input, message, Space, Table } from "antd";
import { useIntl } from "react-intl";
import { getActions, getColumns } from "./columns";
import { Link, useLocation } from "react-router-dom";
import { useQuery } from "react-query";
import jwtAxios from "../../../auth/jwt-api";
import { FaPlus } from "react-icons/fa";

const infos = {
  undeleteablePages: ["action"],
  uncreateablePages: ["action"],
  uneditablePages: ["action"],
};

export default function TablePage() {
  const [searchParams, setSearchParams] = useUrlSearchParams();
  const page = searchParams.pageId;
  const location = useLocation();
  const { messages, formatMessage } = useIntl();
  const t = (id) => formatMessage({ id }) || id;

  const debounceRef = useRef(null);
  const inputRef = useRef("");

  const [pagination, setPagination] = useState({
    current: searchParams.page || 1,
    pageSize: searchParams.rows || 20,
  });

  const apiParams = {
    ...searchParams,
    offset: pagination.current,
    limit: pagination.pageSize,
  };

  const api = getUrl(`/${page}?`, apiParams);

  const { data, isLoading, refetch } = useQuery(api, () => jwtAxios.get(api));

  const columns = getColumns()[page];

  //delete row
  const handleDelete = async (id) => {
    try {
      await jwtAxios.delete(`/${page}/${id}`);
      message.success(messages["notification.success.delete"]);
      refetch();
    } catch (error) {
      message.error(error.response?.data?.error);
      console.log(error);
    }
  };

  return (
    <div>
      <h1>{t(`common.page.${page}`)}</h1>
      <br />
      <div
        style={{
          width: "100%",
          display: "flex",
          gap: 5,
        }}
      >
        <Input
          ref={inputRef}
          defaultValue={searchParams.s}
          autoComplete={"one-time-code"}
          placeholder={t("common.search")}
          onChange={(e) => {
            const value = e.currentTarget.value;

            clearTimeout(debounceRef.current);
            debounceRef.current = setTimeout(() => {
              setPagination({ ...pagination, current: 1 });
              setSearchParams({ ...searchParams, s: value });
            }, 1000);
          }}
        />
        {infos.uncreateablePages.includes(page) || (
          <Link to={`/admin/${page}`}>
            <Button type="primary">
              <FaPlus />
            </Button>
          </Link>
        )}
      </div>
      <br />
      <Table
        loading={isLoading}
        columns={columns?.concat(getActions({ page, handleDelete }))}
        dataSource={data?.data?.data?.results?.rows}
      ></Table>
    </div>
  );
}
