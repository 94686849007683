import { Button, Image, message, Modal, Space } from "antd";
import React, { useRef } from "react";
import styles from "./index.module.scss";
import { useQuery } from "react-query";
import jwtAxios from "../../../../auth/jwt-api";
import { API_URL } from "../../../../shared/constants";
import Loading from "./../../../../layouts/components/Loading/index";
import { clsx } from "clsx";
import OrderModel from "./OrderModel";
import PaymentModel from "./PaymentModel";
import { uz } from "date-fns/locale";
import { format } from "date-fns";
import { MdDelete } from "react-icons/md";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { useAuthDatas } from "../../../../auth/JWTAuthProvider";
import { useReactToPrint } from "react-to-print";
import Print from "./Print";

const { confirm } = Modal;

export default function ApratmentModel({
  searchParams,
  setSearchParams,
  refetchFloor,
}) {
  const { user } = useAuthDatas();
  const contentRef = useRef();
  const reactToPrintFn = useReactToPrint({ contentRef });

  const {
    data: apartmentRes,
    isLoading,
    refetch: refetchInfo,
  } = useQuery(
    ["apartment", searchParams.apartmentId],
    () => jwtAxios.get(`/apartment/${searchParams.apartmentId}`),
    {
      enabled: !!searchParams.apartmentId,
    }
  );

  const apartment = apartmentRes?.data?.data?.apartment;
  const order = apartment?.orders?.[0];

  const deletePayment = (payment) => {
    confirm({
      title: "Rostanam to'lovni o'chirmoqchimisiz?",
      icon: <ExclamationCircleFilled />,
      content: "To'lovni o'chirganingizdan so'ng uni qayta tiklay olmaysiz!",
      okText: "O'chirish",
      cancelText: "Orqaga",
      async onOk() {
        try {
          await jwtAxios.delete(`/payment/${payment.id}`);
          message.success("To'lov o'chirildi");
          refetchInfo();
        } catch (error) {
          message.error(error.response?.data?.error || "To'lov o'o'chirilmadi");
        }
      },
    });
  };
  const deleteOrder = (order) => {
    confirm({
      title: "Rostanam buyurtmani o'chirmoqchimisiz?",
      icon: <ExclamationCircleFilled />,
      content:
        "Buyurtmani o'chirganingizdan so'ng uni qayta tiklay olmaysiz va barcha to'lovlar ham o'chib ketadi!",
      okText: "O'chirish",
      cancelText: "Orqaga",
      async onOk() {
        try {
          await jwtAxios.delete(`/order/${order.id}`);
          message.success("Buyurtma o'chirildi");
          refetchInfo();
          refetchFloor();
        } catch (error) {
          message.error(
            error.response?.data?.error || "Buyurtma o'o'chirilmadi"
          );
        }
      },
    });
  };
  console.log({ contentRef });

  return (
    <>
      <Print contentRef={contentRef} apartment={apartment} />
      <Modal
        open={searchParams.apartmentId}
        onOk={() => setSearchParams({ ...searchParams, orderModel: "true" })}
        onCancel={() => {
          setSearchParams({ ...searchParams, apartmentId: undefined });
        }}
        width={10000}
        title={apartment?.catalog?.name}
        style={{ top: "50%", translate: "0 -50%" }}
        footer={
          <>
            <Button
              onClick={() => {
                setSearchParams({ ...searchParams, apartmentId: undefined });
              }}
            >
              Orqaga
            </Button>
            {apartment && order && (
              <Button onClick={reactToPrintFn} type="primary" ghost>
                Print
              </Button>
            )}{" "}
            {/* {user?.role !== "manager" && ( */}
            <Button
              type="primary"
              onClick={() =>
                setSearchParams({ ...searchParams, orderModel: "true" })
              }
            >
              {order ? "O'zgartirish" : "Sotish"}
            </Button>
            {/* )} */}
          </>
        }
      >
        <div style={{ height: "70vh" }}>
          {isLoading ? (
            <Loading
              style={{
                height: "100%",
              }}
            ></Loading>
          ) : (
            apartment && (
              <div className={styles["model-content"]}>
                <OrderModel
                  setSearchParams={setSearchParams}
                  searchParams={searchParams}
                  refetchFloor={refetchFloor}
                  refetchInfo={refetchInfo}
                  order={order}
                />{" "}
                <div className={styles.image}>
                  <Image
                    src={`${API_URL}/apartment_images/${apartment?.catalog.image_url}`}
                    alt=""
                    style={{
                      width: "100%",
                    }}
                  />
                </div>
                <div className={styles.datas}>
                  <div className={clsx(styles.card)}>
                    <h2>Xonadon ma'lumotlari</h2>
                    <Space>
                      <b>O'lcham:</b>
                      <p>
                        {apartment.catalog.size} m<sup>2</sup>
                      </p>
                    </Space>
                    <br />
                    <Space>
                      <b>Xonalar soni:</b>
                      <p>{apartment.catalog.room_count} ta</p>
                    </Space>
                    <br />
                    <Space>
                      <b>Qavat:</b>
                      <p>{apartment.floor}</p>
                    </Space>{" "}
                    <br />{" "}
                    <Space>
                      <b>Kelishuv holati:</b>
                      <p>
                        {{
                          sold: "Sotildi",
                          reserved: "Bron qilindi",
                          not_full_paid: "To'liq to'lanmadi",
                        }[order?.type] || "Bo'sh"}
                      </p>
                    </Space>
                    <br />
                    <br />
                  </div>
                  {order && (
                    <>
                      <PaymentModel
                        setSearchParams={setSearchParams}
                        searchParams={searchParams}
                        refetchInfo={refetchInfo}
                        order={order}
                      />
                      <div className={clsx(styles.card)}>
                        <h2>Sotuv ma'lumotlari</h2>
                        <Space>
                          <b>Mijoz ismi:</b>
                          <p>{order.customerName}</p>
                        </Space>{" "}
                        <br />
                        <Space>
                          <b>Mijoz raqami:</b>
                          <p>{order.customerPhone}</p>
                        </Space>
                        <br />
                        <Space>
                          <b>Sotilgan narxi:</b>
                          <p>
                            {order.price}{" "}
                            {{ uzs: "So'm", usd: "$" }[order.priceCurrency]}
                          </p>
                        </Space>{" "}
                        <br /> <b>Qo'shimcha ma'lumotlar:</b>
                        <p>{order.description} </p>
                        <br />
                        {user?.role !== "manager" && (
                          <Button
                            danger
                            type="primary"
                            onClick={() => deleteOrder(order)}
                          >
                            Buyurtmani o'chirish
                          </Button>
                        )}
                        <br />
                        <br />
                      </div>
                      <div className={clsx(styles.card, styles.payment)}>
                        <div className={styles.title}>
                          <h2>To'lov ma'lumotlari</h2>
                          <Button
                            type="primary"
                            onClick={() =>
                              setSearchParams({
                                ...searchParams,
                                paymentModel: "true",
                              })
                            }
                          >
                            To'lov qo'shish
                          </Button>
                        </div>
                        <div
                          className={clsx(
                            styles["card"],

                            styles["payment-head"]
                          )}
                        >
                          {" "}
                          <p>Summa</p>
                          <p>To'lov vaqti</p>
                          {user?.role !== "manager" && <p>O'chirish</p>}
                        </div>
                        {order.payments?.map((payment) => (
                          <div
                            className={clsx(
                              styles["card"],
                              styles["payment-card"]
                            )}
                          >
                            <p>
                              {payment.amount}{" "}
                              {{ uzs: "So'm", usd: "$" }[payment.currency]}
                              {payment.type &&
                                ` - ${
                                  { cash: "Naqt", card: "Karta" }[payment.type]
                                }`}
                            </p>
                            <p>
                              {format(payment.createdAt, "dd-MMMM yyyy HH:mm", {
                                locale: uz,
                              })}
                            </p>
                            {/* {user?.role !== "manager" && ( */}
                            <div>
                              <Button
                                danger
                                onClick={() => deletePayment(payment)}
                              >
                                <MdDelete />
                              </Button>
                            </div>
                            {/* )} */}
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                </div>
              </div>
            )
          )}
        </div>
      </Modal>
    </>
  );
}
