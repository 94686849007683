import Schema from "./Schema";
import Catalogs from "./Catalogs";
import BlockSelector from "./BlockSelector";
import FloorSelector from './FloorSelector/index';




/// Routes for authorized users
export const mainRoutes = [
  {
    path: "/main/catalogs",
    element: <Catalogs />,
  },
  {
    path: "/main/schema",
    element: <Schema />,
  },
  {
    path: "/main/block-selector",
    element: <BlockSelector />,
  },
  {
    path: "/main/floor-selector",
    element: <FloorSelector />,
  },
];
