import React, { useState } from "react";
import styles from "./index.module.scss";
import image from "../../../assets/imperium/3d-big.png";
import { clsx } from "clsx";
import { Popover } from "antd";
import Statistics from "./Statistics";
import { useNavigate } from "react-router-dom";

export default function Schema() {
 
  const navigate = useNavigate();
 

  const onClick = (blockNumber) => () => {
    navigate(`/main/schema?floor=2&block=${blockNumber}`);
  };
 

  return (
    <svg
      width="1690"
      height="1024"
      viewBox="0 0 1690 1024"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={styles.svg}
       onLoad={console.log}
    >
      <g clipPath="url(#clip0_227_62)">
        <rect width="1690" height="1024" fill="white" />
        <rect width="1690" height="1024" fill="url(#pattern0_227_62)" />
        <rect width="1690" height="1024" className={styles.black} />

        <path
          d="M180 272.5L226 254L221.5 233.5L269.5 215.5L288.5 228L294 225L292.5 214.5L295 213L301.5 214.5L313 209L310 196.5L374 172L397.5 187L426 175.5V169L423 166.5L429.5 163L440 169V172L464 187L463 184L465.5 183.5L464 169L522.5 147L545 160.5L573.5 150.5V142.844L571.5 140.5L578 138L588 142.5V147L616 163L618.25 190.375L619.375 204.062L620.5 212L627 216V221L622 223L622.5 234.5L629 238V242.5L623.5 245L624.5 255L630.5 258.812L631 263.5L625.5 266L626 276.5L632.5 280.5V285L627 287L628 297L634 300.5L634.5 305L629 308L629.5 317L636 321.5V326L631 328L631.5 337.5L637.5 341V346L632.5 348L633 357L637.5 354.5L638.5 365.5L633 367.5L634 382L621.5 388V394L608.5 400.5V407L592 415.5L594.5 418L581.5 424.5L580 423.5L571.5 428.5L566 425.5L567.5 424.5L564.5 421.5L558 425.5V422L545 414L531.5 420L523.5 414L512 420L498 411L483.5 417L484 422.5L481.5 424.5L480.5 413L476 408.5L445 423L449 438.5L425 450L426 457.5L415.5 463L419.5 487.5L414.5 492V498L426 509.5L428.5 536.5L422.5 540.5L423.5 550.5L434 560L437 582L431.5 586L432.5 596.5L441.5 605L445 626.5L439 630V638L381.5 670L340 631H338.5L192.5 493.5L207 487L209.5 489.5L215.5 486.5L208 461L226.5 449.5L221 444.5L218.5 434.5L222.5 432.5L215.5 407.5L209.5 403L208.5 394L211 392.5L204.5 364.5L198.5 359.5L198 354.5L199.5 347L180 272.5Z"
          fill="url(#pattern1_227_62)"
          className={clsx(styles.block, styles.block1)}
          onClick={onClick(1)}
        />

        <path
          d="M984.556 52L1031.56 71L1031.06 83.5L1032.06 88.5L1039.06 92L1046.56 87.5L1048.56 89V95L1052.56 93.5L1099.06 114V131L1102.06 133V138.5L1112.06 134.5L1124.56 141L1129.56 138.5L1133.06 139.5L1132.06 150L1146.06 156L1170.06 144.5L1226.06 168L1224.06 185.5L1228.06 187V191L1238.56 197.5L1236.56 221.5H1240.06L1239.56 227L1234.06 231.5L1233.56 235.5L1229.56 237L1232.06 223L1225.56 219.5L1207.06 229.5L1205.06 251.5L1185.56 262.5L1183.06 286.5L1176.56 290L1175.56 301.5L1180.56 304L1179.56 309.5L1173.31 312L1172.56 323L1177.56 326L1176.56 332L1170.06 334L1169.06 345L1174.56 348V353L1167.56 357L1166.56 367L1171.56 369.5L1171.06 375L1164.56 377.5L1163.56 388.5L1169.06 390.5V396L1162.06 399V403L1159.56 404.5V408.5L1165.56 412.5L1165.06 416.5L1159.06 419L1157.56 428L1155.06 430L1138.56 420L1131.06 423.5L1121.06 417.5L1113.06 423.5L1104.56 418.5L1104.06 419.5L1090.56 412.5L1093.56 409.5L1077.56 400.5V393.5L1069.06 390V383L1042.06 368L1035.56 370L1025.56 365L1018.56 371L1009.56 366L1008.56 367L996.556 360L1000.06 358L985.556 350L986.056 343.5L975.056 337.5V331.5L965.556 326V323.5L936.556 305L925.556 310.5L908.556 301L900.556 305V311.5L891.056 316.5L894.056 318L896.056 317L900.556 320.5L893.556 324.5L894.056 325L883.056 330.5L878.556 329L863.556 336.5L858.056 333L841.556 341L835.556 338L812.056 350V356L803.056 361L807.556 362L812.556 365.5L804.556 369V371L792.556 376.5L788.556 374.5L772.556 382.5L766.556 379.5L749.556 388L744.056 384.5L726.556 393L674.056 358.5L671.931 328.5L671.556 319L667.056 316.5V307.5L670.338 306L669.806 298.5L668.556 279L664.056 276.5L664.556 268L667.681 268.5L665.556 238.5L661.556 236V225.5L664.056 224.5L660.056 162L678.056 155L677.556 133.5L727.056 114L750.056 125.5L763.056 121V111.5L765.056 110.5L775.056 115.5L774.056 111.5L784.056 107.5L790.056 110L791.556 109V105H795.056L795.556 87.5L843.056 68L871.556 81L871.056 71.5L877.056 70L894.556 77.5L897.056 76.5V67L929.556 52.5L953.556 63L957.056 60.5L962.056 61.5L984.556 52Z"
          fill="url(#pattern2_227_62)"
          className={clsx(styles.block, styles.block4)}
          onClick={onClick(4)}
        />

        <path
          d="M1208.5 229L1228 219L1234.5 222L1233.5 224.5L1231.5 236L1236 235.5V229.5L1268.5 214L1330 241.5L1326.5 259L1329.5 261.5L1329 265L1343 271L1348 268L1356.5 272V277L1370 284L1367.5 292.5L1380 286L1447.5 316.5L1442.5 339.5L1481.5 357.5L1461.5 434.5L1466 436L1464 447.5L1456.5 452.5L1450.5 478.5L1454 481.5L1452 492L1445.5 497L1439 522.5L1442.5 525L1441.5 534.5L1434.5 540L1433 544L1426 569.5L1391 597L1387.5 610L1356.5 633.5L1345 627L1322 645L1320 654.5L1291.5 677L1285.5 673L1280.5 677L1279 688.5L1273 692.5L1265.5 688.5L1251.5 699L1170 647.5L1174 627L1164 619.5L1167 610L1176 604.5L1168 599.5V593.5L1179 583L1171 577.5V572L1181.5 561.5L1173.5 555.5L1174.5 550L1186 539.5L1177 533L1179 524L1189.5 515.5L1180.5 510.5V504.5L1182.5 504L1183 497.5L1174.5 492L1179 487.5L1180.5 489L1183.5 487L1172.5 479.5L1173.5 474L1161 467L1162.5 452L1156 448.5V444L1158 443.5L1158.5 439.5L1164.5 435L1165 431.5L1159.5 429V423L1161.5 419L1167 416L1167.5 411.5L1161.5 407.5V404L1164 402.5V398.5L1171 395.5V390L1165.5 388L1166.5 377L1173 374.5V369L1168.5 366.5L1169.5 356L1176 353V347.5L1171 344.5L1172 333.5L1178.5 331L1179.5 325.5L1174.5 322.5L1175.5 311.5L1181.5 309L1182.5 303.5L1177.5 301L1178.5 289.5L1185 286L1187.5 262L1207 251L1208.5 229Z"
          fill="url(#pattern3_227_62)"
          className={clsx(styles.block, styles.block3)}
          onClick={onClick(3)}
        />

        <path
          d="M767 572L799.5 553L821.5 568L831 563V557.5L850 546.5V532.5L855 529.5V526L872 516L874.5 518L890 509L895.5 512L976.5 463V457.5L982.5 454.5V452L997 443L1013 436L1025 443L1040 434.5L1053 442L1057.5 438.5L1086.5 453.5V438.5L1093.5 433L1117.5 447.5L1114.5 470.5L1144 489L1135 563L1141.5 567.5L1140 578L1132.5 583.5L1129 609L1134.5 612V623.5L1126.5 629.5L1123 654L1129 658V666.5L1121 673L1116 708.5L1086.5 730L1085 741L1052.5 763.5L1044.5 759.5L1036.5 766L1036 777.5L1029.5 783.5L1022.5 777.5L974 813.5V822.5L938 849.5L931.5 845.5L919.5 853.5V864L913.5 869.5L906.5 863L842 910L833.5 904.5L718.5 992.5L413 699.5L426 693L428 695.5L435.5 691L428 664.5L447.5 651.5V648L437 638V628L443 625.5L438.5 603L429 594.5V584L434.5 581L431.5 557.5L421.5 551L420 538L426.5 534.5L424.5 509.5L413 502.5L411 490L417.5 488L414.5 462.5L425 457L424 450L448.5 438L443.5 422.5L474.5 408L479.5 411.5L481 430.5L507.5 417.5L511 419.5L521 412.5L528.5 418L542 411.5L556.5 420.5V424.5L575 438V444L640 491L647.5 487.5L664.5 500.5V503L686 518V533.5L714.5 556L728 546.5L756.5 567.5L759 565.5L767 572Z"
          fill="url(#pattern4_227_62)"
          className={clsx(styles.block, styles.block2)}
          onClick={onClick(2)}
        />
      </g>
      <defs>
        <pattern
          id="pattern0_227_62"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            xlinkHref="#image0_227_62"
            transform="scale(0.00030303 0.000500118)"
          />
        </pattern>
        <pattern
          id="pattern1_227_62"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            xlinkHref="#image0_227_62"
            transform="matrix(0.00111703 0 0 0.000962508 -0.392863 -0.259603)"
          />
        </pattern>
        <pattern
          id="pattern2_227_62"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            xlinkHref="#image0_227_62"
            transform="matrix(0.000882968 0 0 0.0013545 -1.13803 -0.137566)"
          />
        </pattern>
        <pattern
          id="pattern3_227_62"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            xlinkHref="#image0_227_62"
            transform="matrix(0.00157334 0 0 0.00105572 -3.55146 -0.441333)"
          />
        </pattern>
        <pattern
          id="pattern4_227_62"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            xlinkHref="#image0_227_62"
            transform="matrix(0.000698665 0 0 0.000875962 -0.560709 -0.698033)"
          />
        </pattern>
        <clipPath id="clip0_227_62">
          <rect width="1690" height="1024" fill="white" />
        </clipPath>
        <image
          id="image0_227_62"
          width="3300"
          height="2000"
          xlinkHref={image}
        />
      </defs>
    </svg>
  );
}
