import React from "react";
import styles from "./index.module.scss";
import { useAuthActions, useAuthDatas } from "../../../auth/JWTAuthProvider";
import { NavLink, useActionData } from "react-router-dom";
import logo from "../../../assets/imperium/logo.png";
import { Header } from "antd/es/layout/layout";
import menuItems from "../../../pages/main/menuItems";

export default function MainNavbar() {
  const { logout } = useAuthActions();
  const {user} = useAuthDatas()
  return (
    <Header
      style={{
        height: "70px",
      }}
      className={styles.navbar}
    >
      <img src={logo} alt="" />

      {menuItems
        .filter((item) => !(item.roles && item.roles.includes(user.role)))
        .map((item) => (
          <NavLink
            to={item.link}
            className={({ isActive }) => isActive && styles.active}
          >
            {item.messageId}
          </NavLink>
        ))}

      <NavLink to="" onClick={logout} style={{ marginLeft: "auto" }}>
        Chiqish
      </NavLink>
    </Header>
  );
}
