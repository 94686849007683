import { Form, Input, Select, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FormWrapper from "../FormWrapper";
import jwtAxios from "../../../auth/jwt-api";
import useIntl from "react-intl/lib/src/components/useIntl";
import ReactInputMask from "react-input-mask";
import { useLocale } from "../../../i18n/provider";
import { useAuthDatas } from "../../../auth/JWTAuthProvider";

function Users() {
  const [{ user, isLoading }, setUser] = useState({
    user: null,
    isLoading: true,
  });
  const { messages } = useIntl();
  const { language } = useLocale();
  const navigate = useNavigate();
  const { id } = useParams();
  const { user: userData } = useAuthDatas();

  useEffect(() => {
    if (!id) {
      return setUser({ user: null, isLoading: false });
    }
    (async () => {
      try {
        const currentUser = await jwtAxios.get(`/user/${id}`);

        setUser({ user: currentUser?.data?.data?.result, isLoading: false });
      } catch (error) {
        setUser({ user: null, isLoading: false });
      }
    })();
  }, [id]);

  if (isLoading) {
    return "Loading...";
  }

  const onFinish = async (data) => {
    data.phone = data.phone?.replace(/\D/g, "");
    try {
      await jwtAxios[id ? "patch" : "post"](
        `user${id ? `/${id}` : ""}?lang=${language}`,
        data
      );

      message.success(id ? "Successfully updated" : "Successfully posted");

      navigate("/admin/table?pageId=user");
    } catch (error) {
      message.error(error.response?.data?.error);

      console.log({ error });
    }
  };
  const onFinishFailed = (error) => {
    console.log({ error });
  };

  const initialValues = user
    ? { ...user, password: null }
    : { status: "active", role: "manager" };

  return (
    <FormWrapper
      name="basic"
      initialValues={initialValues}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      layout="vertical"
    >
      <div className="form-grid">
        <Form.Item
          name={"username"}
          label={messages["common.username"]}
          rules={[
            { required: true, message: messages["input.required.username"] },
          ]}
        >
          <Input
            autoComplete={"one-time-code"}
            placeholder={`${messages["common.username"]}...`}
            block
          />
        </Form.Item>

        <Form.Item
          name="phone"
          rules={[
            {
              validator(_, data) {
                const phone = data?.replace(/\D/g, "");
                if (phone && phone.length !== 12 && phone.length !== 3) {
                  return Promise.reject(messages["input.error.phone-length"]);
                }
                return Promise.resolve();
              },
            },
          ]}
          label={messages["common.phone"]}
        >
          <ReactInputMask
            style={{
              width: "100%",
            }}
            mask="+\9\98 (99) 999-99-99"
            placeholder="+998 (99) 999-99-99"
            name="phone"
            id="phone"
            className={"antd-input-style"}
            type="tel"
          />
        </Form.Item>

        <Form.Item
          label={messages["common.role"]}
          name="role"
          rules={[{ required: true, message: messages["input.required.role"] }]}
        >
          <Select
            allowClear
            style={{
              width: "100%",
            }}
            placeholder={`${messages["common.role"]}...`}
            options={
              userData.role === "admin"
                ? [
                    {
                      label: "Sotuvchi",
                      value: "manager",
                    },
                  ]
                : [
                    {
                      label: "Sotuvchi",
                      value: "manager",
                    },
                    {
                      label: "Admin",
                      value: "admin",
                    },
                    {
                      label: "Superadmin",
                      value: "superadmin",
                    },
                  ]
            }
          />
        </Form.Item>

        <Form.Item
          label={messages["common.password"]}
          name="password"
          rules={[
            { required: !id, message: messages["input.required.password"] },
            {
              validator(_, data) {
                if (!data || data.length > 7) {
                  return Promise.resolve();
                }
                return Promise.reject(messages["input.error.password<8"]);
              },
            },
          ]}
        >
          <Input.Password
            autoComplete={"one-time-code"}
            placeholder={`${messages["common.password"]}...`}
          />
        </Form.Item>

        <Form.Item
          label={messages["common.status"]}
          name="status"
          rules={[
            { required: true, message: messages["input.required.status"] },
          ]}
        >
          <Select
            allowClear
            style={{
              width: "100%",
            }}
            placeholder={`${messages["common.status"]}...`}
            options={[
              {
                label: "Aktiv",
                value: "active",
              },
              {
                label: "Bloklangan",
                value: "inactive",
              },
            ]}
          />
        </Form.Item>
      </div>
    </FormWrapper>
  );
}

export default Users;
