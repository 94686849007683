import React, { useState } from "react";
import { Slider, InputNumber, Row, Col } from "antd";


export const minRoomSize = 20,
  maxRoomSize = 150;

const NumberRangeSelector = ({ searchParams, setSearchParams }) => {
  const onChange = (value) => {
    setSearchParams({
      ...searchParams,
      minArea: value[0],
      maxArea: value[1],
    });
  };

  return (
    <div className="filter-card slider">
      <p>
        Xonadon maydoni: {searchParams.minArea} m<sup>2</sup> -{" "}
        {searchParams.maxArea} m<sup>2</sup>
      </p>
      <Slider
        range
        value={[searchParams.minArea, searchParams.maxArea]}
        min={minRoomSize}
        max={maxRoomSize}
        onChange={onChange}
      />
      <div className="inputs">
        <InputNumber
          min={minRoomSize}
          max={maxRoomSize}
          value={searchParams.minArea}
          onChange={(value) =>
            setSearchParams({ ...searchParams, minArea: value })
          }
          addonAfter={
            <>
              m<sup>2</sup>
            </>
          }
        />

        <InputNumber
          min={minRoomSize}
          max={maxRoomSize}
          value={searchParams.maxArea}
          onChange={(value) =>
            setSearchParams({ ...searchParams, maxArea: value })
          }
          addonAfter={
            <>
              m<sup>2</sup>
            </>
          }
        />
      </div>
    </div>
  );
};

export default NumberRangeSelector;
