import React from "react";
import { Checkbox, Space } from "antd";

export default function RoomCount({ searchParams, setSearchParams }) {
  return (
    <div className="filter-card">
      <p>Xonalar soni:</p>
      <div
       className="checkbox-container"
      >
        {[1, 2, 3, 4, 5].map((count) => (
          <div >
            <Checkbox
              checked={searchParams.roomCount
                .split(",")
                .includes(String(count))}
              onClick={(e) => {
                e.target.checked
                  ? setSearchParams({
                      ...searchParams,
                      roomCount: [
                        ...searchParams.roomCount.split(","),
                        count,
                      ].join(","),
                    })
                  : setSearchParams({
                      ...searchParams,
                      roomCount: searchParams.roomCount
                        .split(",")
                        .filter((currentCount) => count != currentCount)
                        .join(","),
                    });
              }}
            >
              {count}ta Xona{" "}
            </Checkbox>
          </div>
        ))}
      </div>
    </div>
  );
}
