import React from "react";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import { Avatar, Dropdown, List } from "antd";
import { FaChevronDown } from "react-icons/fa";
import "./index.style.scss";
import { useAuthActions, useAuthDatas } from "../../auth/JWTAuthProvider";

const UserInfo = () => {
  const { logout } = useAuthActions();
  const { user } = useAuthDatas();
  const navigate = useNavigate();

  const getUserAvatar = () => {
    if (user.full_name) {
      return user.full_name.charAt(0).toUpperCase();
    }
    if (user.phone) {
      return user.phone.charAt(0).toUpperCase();
    }
  };

 
  const menu = (
    <List className="dropdown-list">
      <List.Item onClick={() => navigate("/main/profile")}>
        My Profile
      </List.Item>
      <List.Item onClick={() => logout()}>Logout</List.Item>
    </List>
  );

  return (
    <>
      <div
        className={clsx("cr-user-info", {
          light: true,
        })}
      >
        <Dropdown
          className="user-profile-dropdown"
          overlay={menu}
          trigger={["click"]}
          placement="bottomRight"
          overlayStyle={{
            zIndex: 1052,
            minWidth: 150,
          }}
        >
          <a className="cr-user-info-inner ant-dropdown-link">
            {user.photoURL ? (
              <Avatar className="cr-user-info-avatar" src={user.photoURL} />
            ) : (
              <Avatar className="cr-user-info-avatar">{getUserAvatar()}</Avatar>
            )}
            <span className="cr-user-info-content">
              <span className="cr-user-name-info">
                <h3
                  className={clsx("cr-user-name text-truncate", {
                    light: true,
                  })}
                >
                  {user.username ? user.username : user.phone}
                </h3>
                <span className="cr-user-arrow">
                  <FaChevronDown />
                </span>
              </span>
              <span className="cr-user-designation text-truncate">
                {user.role}
              </span>
            </span>
          </a>
        </Dropdown>
      </div>
    </>
  );
};

export default UserInfo;
