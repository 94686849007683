import axios from "axios";
import { API_URL } from "../shared/constants";
  
const jwtAxios = axios.create({
  baseURL: `${API_URL}/api`, //YOUR_API_URL HERE
  headers: {
    "Content-Type": "application/json","ngrok-skip-browser-warning":"69420"
  },
});
jwtAxios.interceptors.response.use(
  (res) => res,
  (err) => {
     
     if (err.response.status === 401) {
       localStorage.removeItem("token");
       window.location.replace("/");
     }

 

    return Promise.reject(err);
  }
);

export const setAuthToken = (token) => {
  if (token) {
    jwtAxios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    localStorage.setItem("token", token);
  } else {
    delete jwtAxios.defaults.headers.common["Authorization"];
    localStorage.removeItem("token");
  }
};

export default jwtAxios;
