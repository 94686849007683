import {
  Button,
  Form,
  Input,
  Modal,
  Select,
  Space,
  Spin,
  InputNumber,
  message,
  Flex,
} from "antd";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styles from "./index.module.scss";
import jwtAxios from "../../../../auth/jwt-api";
import { useUrlSearchParams } from "use-url-search-params";
import TextArea from "antd/es/input/TextArea";
import ReactInputMask from "react-input-mask";
import { ExclamationCircleFilled } from "@ant-design/icons";
const { confirm } = Modal;

export default function OrderModel({
  refetchInfo,
  refetchFloor,
  order,
  searchParams,
  setSearchParams,
}) {
  const isOpen = searchParams.orderModel;
  const [loading, setLoading] = useState(false);
  const [currency, setCurrency] = useState("uzs");
  const [form] = Form.useForm();

  const onFinish = async (data) => {
    // confirm({
    //   title: "Rostanam uyni sotmoqchimisiz?",
    //   icon: <ExclamationCircleFilled />,
    //   content:
    //     "Uy sotilgandan so'ng uni faqat adminlargina o'chira olishi mumkin bo'ladi!",
    //   okText: "Yaratish",
    //   cancelText: "Orqaga",
    //   async onOk() {
    setLoading(true);
    data.priceCurrency = currency;
    data.apartmentId = searchParams.apartmentId;
    try {
      if (order?.id) {
        await jwtAxios.patch(`/order/${order.id}`, data);
      } else {
        await jwtAxios.post(`/order`, data);
      }
      refetchInfo();
      refetchFloor();
      setSearchParams({
        ...searchParams,
        orderModel: undefined,
      });
      message.success("Muvoffaqiyatli o'zgartirildi");
    } catch (error) {
      message.error(error.response?.data?.error || "Xatolik yuz berdi");
      console.log(error);
    }
    setLoading(false);
    // },
    // });
  };
  useEffect(() => {
    if (order) {
      setCurrency(order.priceCurrency);

      form.setFields(
        Object.keys(order).map((key) => ({
          name: key,
          value: order[key],
        }))
      );
    }
  }, [isOpen, order?.id]);

  const onCancel = () => {
    setSearchParams({
      ...searchParams,
      orderModel: undefined,
    });
  };

  return (
    <Modal
      centered
      open={!!isOpen}
      onCancel={onCancel}
      width={1000}
      title="Sotuv ma'lumotlari"
      footer={""}
    >
      <Form
        onFinish={onFinish}
        layout="vertical"
        initialValues={{
          type: "reserved",
        }}
        form={form}
      >
        <div
          style={{
            maxHeight: "50vh",
            overflowX: "auto",
          }}
        >
          <div className={styles.form}>
            <Form.Item
              label="Mijoz to'liq ismi"
              name="customerName"
              rules={[
                {
                  required: true,
                  message: "Mijozni ismini kiritish majburiy!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              rules={[
                { required: true, message: "Mijoz raqamini kiritish majburiy" },
                {
                  validator(_, data) {
                    const phone = data?.replace(/\D/g, "");
                    if (phone && phone.length !== 12 && phone.length !== 3) {
                      return Promise.reject("Iltimoz raqamni to'liq kiriting!");
                    }
                    return Promise.resolve();
                  },
                },
              ]}
              label="Mijoz raqami"
              name="customerPhone"
            >
              <ReactInputMask
                style={{
                  width: "100%",
                }}
                mask="+\9\98 (99) 999-99-99"
                placeholder="+998 (99) 999-99-99"
                name="phone"
                id="phone"
                className={"antd-input-style"}
                type="tel"
              />
            </Form.Item>
            <Form.Item
              label="Kelishilgan narxi:"
              name="price"
              rules={[
                {
                  required: true,
                  message: "Kelishilgan narxni kiritish majburiy!",
                },
              ]}
            >
              <InputNumber
                style={{ display: "block", width: "100%" }}
                type="number"
                addonAfter={
                  <Select
                    style={{ width: "clamp(50px, 7vw, 100px)" }}
                    onChange={setCurrency}
                    value={currency}
                    options={[
                      {
                        label: "$",
                        value: "usd",
                      },

                      {
                        label: "So'm",
                        value: "uzs",
                      },
                    ]}
                  ></Select>
                }
              />
            </Form.Item>
            <Form.Item label="Sotuv holati" name="type">
              <Select
                options={[
                  {
                    label: "Bron qilindi",
                    value: "reserved",
                  },
                  {
                    label: "Sotildi",
                    value: "sold",
                  },
                  {
                    label: "To'liq to'lanmadi",
                    value: "not_full_paid",
                  },
                ]}
              />
            </Form.Item>
          </div>
          <Form.Item label="Qo'shimcha ma'lumotlar" name="description">
            <TextArea />
          </Form.Item>
        </div>

        <Form.Item>
          <Flex
            align="flex-end"
            justify="flex-end"
            gap={10}
            wrap="nowrap"
            style={{ paddingTop: 20 }}
          >
            <Button
              type="primary"
              ghost
              htmlType="button"
              loading={loading}
              onClick={onCancel}
            >
              Orqaga
            </Button>
            <Button type="primary" htmlType="submit" loading={loading}>
              Jo'natish{" "}
            </Button>
          </Flex>
        </Form.Item>
      </Form>
    </Modal>
  );
}
