import { Button, Form } from "antd";
import React, { useState } from "react";
import useIntl from "react-intl/lib/src/components/useIntl";
import { useNavigate } from "react-router-dom";

function FormWrapper({ children, onCancel, ...rest }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { messages } = useIntl();

  return (
    <Form
      {...rest}
      onFinish={(...values) => {
        setLoading(true);
        rest.onFinish(...values).finally(() => {
          setLoading(false);
        });
      }}
    >
      {children}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          paddingBlock: "30px",
          gap: 10,
        }}
      >
        <Button
          primary
          htmlType="button"
          onClick={onCancel || (() => navigate(-1))}
        >
          Orqaga
        </Button>
        <Form.Item className="grid-block">
          <Button type="primary" htmlType="submit" loading={loading}>
            Jo'natish
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
}

export default FormWrapper;
