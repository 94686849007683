import Users from "./forms/Users";
import TablePage from "./Table";

 
export const adminRoutes = [
  {
    path: "/admin/table",
    element: <TablePage />,
  },
  {
    path: ["/admin/user", "/admin/user/:id"],
    element: <Users />,
  },
];
 