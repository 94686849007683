import React, { useState } from "react";
import Schema from "./Schema";
 import styles from "./index.module.scss";
 
export default function FloorSelector() {
  return (
    <div style={{}} className={styles.floorSelector}>
      <Schema></Schema>
    </div>
  );
}
