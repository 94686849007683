import { Menu } from "antd";
import Sider from "antd/es/layout/Sider";
import React from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { useUrlSearchParams } from "use-url-search-params";
// import image from "../../../assets/izumrud/sidebar-bg.jpg";
import UserInfo from "../../../components/UserInfo";
import menuItems from "../../../pages/admin/menuItems";
import { useAuthDatas } from "../../../auth/JWTAuthProvider";

export default function Sidebar({ collapsed }) {
  const location = useLocation();
  const [{ pageId }] = useUrlSearchParams();
  const { user } = useAuthDatas();
  const pathList = location.pathname.split("/");
  const activeMenu = [
    pageId,
    pathList[pathList.length - 1],
    pathList[pathList.length - 2],
  ];

   
  return (
    <Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
      collapsedWidth={50}
      theme="light"
      style={{
        overflowY: "auto",
        overflowX: "hidden",
        height: "100vh",
        backgroundSize: "auto 130%",
        backgroundPosition: "20% 0%",
      }}
    >
      <UserInfo></UserInfo>
      <Menu theme="light" mode="inline" selectedKeys={activeMenu}>
        {menuItems
          .filter((item) => !item.roles || item.roles.includes(user.role))
          .map((item) => (
            <Menu.Item key={item.key} icon={item.icon}>
              <Link to={item.link}>{item.messageId}</Link>
            </Menu.Item>
          ))}
      </Menu>
    </Sider>
  );
}
