import React from "react";
import styles from "./index.module.scss";
import image from "../../../assets/imperium/floor2.png";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { Popover } from "antd";

export default function Schema() {
  const navigate = useNavigate();

  const onClick = (floorNumber) => () => {
    navigate(`/main/schema?block=all&floor=${floorNumber}`);
  };

  return (
    <svg
      width="1111"
      height="873"
      viewBox="0 0 1111 873"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={styles.svg}
    >
      <g clip-path="url(#clip0_219_2)">
        <rect width="1111" height="873" fill="white" />
        <rect x="-329" width="1440" height="873" fill="url(#pattern0_219_2)" />
        <Popover placement={"rightBottom"} content={9}>
          <path
            d="M713.871 284.002L713.678 283.758L713.374 283.823L531.599 322.775L523.283 316.415L523.099 316.275L522.873 316.324L418.873 338.824L418.479 338.909L418.479 339.312L418.479 351.905L341.499 367.798L333.618 365.52L333.498 365.485L333.377 365.511L259.877 380.823L259.871 380.824L180.99 398.188L181.208 385.261L189.771 384.31L190.212 384.261L190.216 383.817L190.496 350.934L211.071 347.995L211.5 347.934L211.5 347.5L211.5 343.387L260.104 330.797L260.479 330.699L260.479 330.313L260.479 321.713L333.376 305.348L341.196 310.725L341.384 310.854L341.604 310.797L420.125 290.484L420.5 290.387L420.5 290L420.5 277.404L522.837 255.354L531.148 262.687L531.346 262.862L531.603 262.797L713.264 216.383L761.066 286.095L761.215 286.313L761.479 286.312L777.203 286.312L805.056 330.579L805.222 330.843L805.532 330.81L861.726 324.842L878.059 350.084L878.233 350.353L878.549 350.307L892.197 348.358L908.034 379.042L908.174 379.313L908.479 379.313L910.67 379.312L912.479 382.931L912.479 439.247L904.426 423.589L904.423 423.584L892.426 399.589L892.27 399.277L891.924 399.316L878.749 400.779L861.912 371.563L861.739 371.263L861.398 371.319L805.705 380.456L777.886 341.522L777.7 341.262L777.387 341.321L761.683 344.266L713.871 284.002Z"
            fill="#FF1010"
            fill-opacity="0.33"
            stroke="white"
            className={clsx(styles.floor, styles.floor1)}
            onClick={onClick(9)}
          />
        </Popover>
        <Popover placement={"rightBottom"} content={10}>
          <path
            d="M449.432 251.248L446.29 245.861L453.592 244.491L454 244.415L454 244L454 234.562L469.367 230.06L484.659 244.365L485.472 245.126L485.5 244.013L486.49 205.386L597.321 175.566L629.616 214.32L629.815 214.558L630.116 214.486L738.763 188.571L777 251.141L777 275.5L777 276.102L777.592 275.991L801.254 271.555L804.938 277.541L803.67 280.5L801.5 280.5L801 280.5L801 281L801 291L801 291.131L801.064 291.245L810.064 307.245L810.239 307.556L810.59 307.492L878.203 295.063L897.495 338.594L897.001 348.976L896.995 349.099L897.047 349.211L903.492 363.092L903.251 366.212L903.241 366.347L903.3 366.468L907.55 375.218L907.687 375.5L908 375.5L911.17 375.5L912.5 378.603L912.5 380.591L911.186 378.255L911.042 378L910.75 378L908.794 378L903.947 369.276L903.937 369.257L892.947 347.276L892.786 346.954L892.429 347.005L878.746 348.96L862.42 323.728L862.253 323.47L861.947 323.503L805.759 329.47L777.925 284.736L777.778 284.5L777.5 284.5L761.762 284.5L713.957 215.387L713.759 215.1L713.422 215.187L531.632 261.95L523.331 254.625L523.142 254.458L522.896 254.511L419.896 276.511L419.5 276.596L419.5 277L419.5 289.614L341.595 309.959L333.784 304.588L333.604 304.464L333.391 304.512L259.891 321.012L259.5 321.1L259.5 321.5L259.5 330.112L210.877 342.516L210.5 342.612L210.5 343L210.5 347.066L190.5 349.924L190.5 336.402L210.608 331.988L211 331.902L211 331.5L211 324.366L221.652 320.977L222 320.866L222 320.5L222 281.384L341.401 249.544L388.709 283.407L388.905 283.547L389.137 283.481L419.137 274.981L419.5 274.878L419.5 274.5L419.5 266.398L432.113 263.487L432.5 263.398L432.5 263L432.5 251.872L436.91 250.549L448.711 258.908L449.5 259.467L449.5 258.5L449.5 251.5L449.5 251.365L449.432 251.248Z"
            fill="#000DFF"
            fill-opacity="0.31"
            stroke="white"
            className={clsx(styles.floor, styles.floor2)}
            onClick={onClick(10)}
          />
        </Popover>
        <Popover placement={"rightBottom"} content={8}>
          <path
            d="M912.502 455.45L910.154 478.937L882.43 432.245L882.265 431.966L881.943 432.003L864.873 433.954L862.484 424.873L862.378 424.471L861.963 424.501L801.711 428.983L753.375 374.169L753.209 373.981L752.96 374.002L734.715 375.481L714.426 351.676L714.235 351.452L713.947 351.51L639.141 366.462L632.326 360.62L632.145 360.466L631.912 360.508L523.415 380.007L523.412 380.008L419.415 398.007L419 398.079L419 398.5L419 409.762L409.686 406.036L409.55 405.982L409.406 406.009L333.416 420.507L333.406 420.509L210.916 441.507L210.5 441.579L210.5 442L210.5 448.564L189.501 451.427L189.5 411L189.5 410.64L189.158 410.526L180.5 407.64L180.5 398.907L333.49 367.013L341.379 368.985L341.491 369.013L341.604 368.989L419.104 352.489L419.5 352.405L419.5 352L419.5 339.403L522.871 317.04L530.685 323.388L530.871 323.539L531.105 323.489L713.845 284.554L761.607 345.309L761.802 345.557L762.11 345.488L777.285 342.061L805.092 381.289L805.273 381.545L805.583 381.493L861.736 372.051L878.064 401.244L878.219 401.522L878.537 401.499L891.704 400.523L912.546 441.71L912.554 441.726L915.5 448.11L915.5 455L913 455L912.548 455L912.502 455.45Z"
            fill="#000DFF"
            fill-opacity="0.31"
            stroke="white"
            className={clsx(styles.floor, styles.floor3)}
            onClick={onClick(8)}
          />
        </Popover>
        <Popover placement={"rightBottom"} content={7}>
          <path
            d="M420 411L420 398.92L631.864 361.532L639.187 367.391L639.369 367.536L639.598 367.49L714.302 352.55L733.612 376.315L733.778 376.519L734.039 376.498L752.79 375.018L801.125 429.831L801.289 430.017L801.537 429.999L861.617 425.53L864.015 435.121L864.124 435.559L864.571 435.495L881.74 433.042L910.011 480.656L913.502 523.041L913.504 523.066L913.508 523.091L915.635 534.573L913.456 531.772L911.982 526.368L911.96 526.287L911.912 526.217L906.412 518.217L906.263 518L906 518L904.261 518L892.91 501.714L892.783 501.531L892.562 501.504L885 500.559L885 497L885 491L885 490.836L884.902 490.703L877.903 481.203L877.736 480.978L877.457 481.002L802.199 487.481L765.365 448.158L765.36 448.153L740.36 422.153L740.186 421.972L739.936 422.004L720.626 424.48L712.783 419.088L712.622 418.977L712.428 419.005L692.176 421.969L685.853 415.646L685.672 415.465L685.418 415.507L603.419 429.007L603 429.076L603 429.5L603 434.067L531.011 444.493L523.092 443.009L523.002 442.992L522.912 443.008L419.422 461.506L419.417 461.507L384.422 467.006L384 467.072L384 467.5L384 473.065L341.598 478.981L333.745 474.564L333.594 474.479L333.423 474.506L180.572 498.404L181.952 486.444L189.066 485.496L189.5 485.438L189.5 485L189.5 452.437L211.067 449.496L211.499 449.437L211.499 449L211.5 442.418L409.435 407.02L419.295 411.456L420 411.773L420 411Z"
            fill="#FF1010"
            fill-opacity="0.33"
            stroke="white"
            className={clsx(styles.floor, styles.floor4)}
            onClick={onClick(7)}
          />
        </Popover>
        <Popover placement={"rightBottom"} content={6}>
          <path
            d="M912.5 543.5L912.5 572.667L882.377 538.171L882.215 537.986L881.97 538.001L865.588 538.994L862.197 537.54L862.097 537.497L861.988 537.5L802.18 538.995L753.325 497.12L753.156 496.975L752.935 497.004L734.156 499.475L713.325 481.62L713.155 481.474L712.932 481.505L640.115 491.479L633.27 487.079L633.119 486.982L632.939 487.004L522.939 500.504L522.93 500.505L522.921 500.506L419.421 517.006L419 517.073L419 517.5L419 524.133L410.251 519.067L410.104 518.982L409.937 519.004L210.936 544.504L210.5 544.56L210.5 545L210.5 548.555L190 550.938L190 511.5L190 511.114L189.627 511.016L180.5 508.615L180.5 498.929L333.405 475.52L341.255 479.936L341.402 480.019L341.57 479.995L384.07 473.995L384.5 473.934L384.5 473.5L384.5 467.921L522.996 444.008L530.908 445.491L530.991 445.507L531.075 445.494L603.575 434.494L604 434.43L604 434L604 429.924L684.839 416.533L691.66 422.866L691.835 423.029L692.071 422.995L712.872 420.023L719.709 424.907L719.863 425.016L720.05 424.998L739.806 423.022L801.637 488.344L801.803 488.519L802.043 488.498L877.264 482.022L884 491.164L884 501L884 501.441L884.438 501.496L892.217 502.469L903.59 518.786L903.739 519L904 519L905.737 519L911.068 526.755L915.974 536.567L914.596 543L913 543L912.5 543L912.5 543.5Z"
            fill="#000DFF"
            fill-opacity="0.31"
            stroke="white"
            className={clsx(styles.floor, styles.floor5)}
            onClick={onClick(6)}
          />
        </Popover>
        <Popover placement={"rightBottom"} content={5}>
          <path
            d="M419.5 524.5L419.5 517.991L523.561 501.496L523.578 501.494L632.392 488.081L639.745 492.431L639.895 492.519L640.068 492.496L712.842 482.527L733.17 500.376L733.34 500.525L733.564 500.496L752.843 498.024L801.675 539.88L801.82 540.005L802.012 540L861.903 538.503L865.303 539.96L865.419 540.009L865.545 539.998L881.792 538.521L912.995 574.182L912 609.986L911.991 610.305L912.276 610.447L913.985 611.302L913.559 620.257L905.873 611.667L905.724 611.5L905.5 611.5L904.187 611.5L892.828 601.623L892.687 601.5L892.5 601.5L885 601.5L885 594.5L885 594.27L884.825 594.12L877.825 588.12L877.678 587.994L877.483 588L862.483 588.5L862.471 588.501L802.207 591.987L778.367 566.161L778.203 565.983L777.962 566.001L765.143 566.988L740.791 549.593L740.638 549.484L740.451 549.502L720.11 551.487L713.248 547.566L713.105 547.484L712.941 547.504L692.129 549.981L685.785 545.589L685.631 545.483L685.446 545.503L602.946 554.503L602.5 554.552L602.5 555L602.5 558.549L531.08 565.989L523.2 562.542L523.079 562.489L522.948 562.503L182.5 598.444L182.5 586.968L189.533 586.499L190 586.468L190 586L190 551.945L211.058 549.497L211.5 549.445L211.5 549L211.5 545.504L409.902 520.081L418.76 524.938L419.5 525.345L419.5 524.5Z"
            fill="#FF1010"
            fill-opacity="0.33"
            stroke="white"
            className={clsx(styles.floor, styles.floor6)}
            onClick={onClick(5)}
          />
        </Popover>
        <Popover placement={"rightBottom"} content={4}>
          <path
            d="M419 631V634.36L409.621 632.015L409.544 631.995L409.464 632.001L210.964 646.501L210.5 646.535V647V651.023L190 651.976V611V610.573L189.578 610.506L180.5 609.073V599.45L523.414 563.512L530.789 566.953L530.914 567.012L531.052 566.997L603.052 559.497L603.5 559.451V559V555.448L684.879 546.516L691.73 550.921L691.881 551.018L692.059 550.996L712.895 548.516L719.752 552.434L719.891 552.513L720.05 552.498L739.865 550.516L764.713 567.91L764.86 568.012L765.038 567.999L777.797 567.017L801.633 592.839L801.792 593.012L802.026 592.999L877.327 589.01L884 594.73V602V602.5H884.5H892.313L903.672 612.377L903.813 612.5H904H905.276L913.627 621.833L913.637 621.844L913.646 621.854L915 623.207V630.5H913H912.5V631V663.117L910.772 662.081L910.757 662.071L882.272 643.581L882.141 643.496L881.985 643.5L865 644L864.985 644L800.618 642.003L778.211 631.547L778.209 631.546L753.209 620.046L753.097 619.994L752.974 620.001L734.085 620.995L713.188 612.537L713.081 612.493L712.966 612.501L639.58 617.493L632.186 614.536L632.077 614.492L631.961 614.502L522.964 623.001L522.961 623.002L419.464 630.501L419 630.535V631Z"
            fill="#000DFF"
            fill-opacity="0.31"
            stroke="white"
            className={clsx(styles.floor, styles.floor7)}
            onClick={onClick(4)}
          />
        </Popover>
        <Popover placement={"rightBottom"} content={3}>
          <path
            d="M419.999 634.999L419.999 631.463L631.921 615.507L639.314 618.464L639.419 618.506L639.533 618.499L713.416 613.506L733.808 621.962L733.913 622.005L734.026 621.999L752.902 621.006L800.29 642.953L800.385 642.998L800.491 643L881.848 644.497L912.5 664.272L912.5 698.5L912.5 698.809L912.776 698.947L913.5 699.309L913.5 709.254L877.69 694.537L877.591 694.497L877.484 694.5L800.608 696.996L778.22 686.051L778.116 686L778 686L764.58 686L740.155 678.025L740.08 678L740 678L720.126 678L713.736 674.56L713.614 674.494L713.476 674.5L182.5 699.476L182.5 687.5L189.499 687.5L189.999 687.5L189.999 687L189.999 652.977L211.022 651.999L211.499 651.977L211.499 651.5L211.499 647.464L409.952 633.004L419.372 635.483L419.999 635.648L419.999 634.999Z"
            fill="#FF1010"
            fill-opacity="0.33"
            stroke="white"
            className={clsx(styles.floor, styles.floor8)}
            onClick={onClick(3)}
          />
        </Popover>
        <Popover placement={"rightBottom"} content={2}>
          <path
            d="M912 719.485L911.016 752.953L863.046 748.502L863.019 748.5L862.992 748.5L800.527 749.499L752.562 743.504L752.541 743.501L752.519 743.5L713.519 742L713.505 742L713.491 742L190 750.991V711V710.526L189.526 710.501L180.5 710.026V700.977L713.393 675.506L720.276 678.947L720.382 679H720.5H738.923L764.35 686.977L764.423 687H764.5H777.884L800.28 697.949L800.392 698.004L800.516 698L876.912 695.503L915.5 710.839V719H912.5H912.014L912 719.485Z"
            fill="#000DFF"
            fill-opacity="0.31"
            stroke="white"
            className={clsx(styles.floor, styles.floor9)}
            onClick={onClick(2)}
          />
        </Popover>
      </g>
      <defs>
        <pattern
          id="pattern0_219_2"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            xlinkHref="#image0_219_2"
            transform="scale(0.000303125 0.0005)"
          />
        </pattern>
        <clipPath id="clip0_219_2">
          <rect width="1111" height="873" fill="white" />
        </clipPath>
        <image id="image0_219_2" width="3300" height="2000" xlinkHref={image} />
      </defs>
    </svg>
  );
}
