//This is a login page

import React from "react";
import logo from "../../../assets/imperium/logo.png";
import styles from "./index.module.scss";
import { Button, Checkbox, Form, Input } from "antd";
import { useAuthActions } from "../../../auth/JWTAuthProvider";
 
export default function Login() {
  const { signInUser } = useAuthActions();

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <img src={logo} alt="" />

        <Form
          onFinish={signInUser}
          initialValues={
            process.env.REACT_APP_NODE_ENV === "production"
              ? {
                  rememberMe: true,
                }
              : {
                  rememberMe: true,
                  username:"root",
                  password:"root"
                }
          }
        >
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: "Foydalanuvchi ismini kiritish majburiy!",
              },
            ]}
          >
            <Input placeholder={"Foydalanuvchi:"} />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[
              () => ({
                validator(_, value) {
                  if (!value) {
                    return Promise.reject(
                      new Error("Parolni kiritish majburiy!")
                    );
                  }
                  return Promise.resolve();

                  // return Promise.reject(
                  //   new Error("Password length must be at least 8 characters")
                  // );
                },
              }),
            ]}
          >
            <Input.Password placeholder={"Parol:"} />
          </Form.Item>
          <Form.Item name="rememberMe" valuePropName="checked">
            <Checkbox>Eslab qolish</Checkbox>
          </Form.Item>
          <Form.Item>
            <Button style={{ width: "100%" }} htmlType="submit" type="primary">
              Kirish
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
