import {
  Button,
  Form,
  Input,
  Modal,
  Select,
  Space,
  Spin,
  InputNumber,
  message,
  Flex,
} from "antd";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styles from "./index.module.scss";
import jwtAxios from "../../../../auth/jwt-api";
import { useUrlSearchParams } from "use-url-search-params";
import TextArea from "antd/es/input/TextArea";
import ReactInputMask from "react-input-mask";
import { ExclamationCircleFilled } from "@ant-design/icons";
const { confirm } = Modal;

export default function PaymentModel({
  refetchInfo,
  order,
  searchParams,
  setSearchParams,
}) {
  const isOpen = searchParams.paymentModel;
  const [loading, setLoading] = useState(false);
  const [currency, setCurrency] = useState("uzs");
  const [form] = Form.useForm();

  const onFinish = async (data) => {
    // confirm({
    //   title: "Rostanam to'lov qo'shmoqchimisiz?",
    //   icon: <ExclamationCircleFilled />,
    //   content:
    //     "To'lov qo'shilgandan so'ng so'ng uni faqat adminlargina o'chira olishi mumkin bo'ladi!",
    //   okText: "Yaratish",
    //   cancelText: "Orqaga",
    //   async onOk() {
    setLoading(true);
    data.currency = currency;
    data.orderId = order.id;
    try {
      await jwtAxios.post(`/payment`, data);
      refetchInfo();
      setSearchParams({
        ...searchParams,
        paymentModel: undefined,
      });
      form.setFields([
        {
          name: "amount",
          value: null,
        },
      ]);
      message.success("Muvoffaqiyatli o'zgartirildi");
    } catch (error) {
      message.error(error.response?.data?.error || "Xatolik yuz berdi");
      console.log(error);
    }
    setLoading(false);
    //   },
    // });
  };

  const onCancel = () => {
    setSearchParams({
      ...searchParams,
      paymentModel: undefined,
    });
  };

  return (
    <Modal
      centered
      open={!!isOpen}
      onCancel={onCancel}
      width={500}
      title="To'lov qo'shish"
      footer={""}
    >
      <br />
      <Form
        onFinish={onFinish}
        layout="vertical"
        initialValues={{
          type: "cash",
        }}
        form={form}
      >
        <Form.Item
          label="To'langan summa:"
          name="amount"
          rules={[
            {
              required: true,
              message: "To'langan summani kiritish majburiy!",
            },
          ]}
        >
          <InputNumber
            style={{ display: "block", width: "100%" }}
            placeholder="Summa:"
            type="number"
            addonAfter={
              <Select
                style={{ width: "clamp(50px, 7vw, 100px)" }}
                onChange={setCurrency}
                value={currency}
                options={[
                  {
                    label: "$",
                    value: "usd",
                  },

                  {
                    label: "So'm",
                    value: "uzs",
                  },
                ]}
              ></Select>
            }
          />
        </Form.Item>
        <Form.Item label="To'lov turi" name="type">
          <Select
            options={[
              {
                label: "Karta",
                value: "card",
              },
              {
                label: "Naqt pul",
                value: "cash",
              },
            ]}
          />
        </Form.Item>

        <Form.Item>
          <Flex align="flex-end" justify="flex-end" gap={10} wrap="nowrap">
            <Button
              type="primary"
              ghost
              htmlType="button"
              loading={loading}
              onClick={onCancel}
            >
              Orqaga
            </Button>
            <Button type="primary" htmlType="submit" loading={loading}>
              Jo'natish{" "}
            </Button>
          </Flex>
        </Form.Item>
      </Form>
    </Modal>
  );
}
