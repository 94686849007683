import { Space } from "antd";
import clsx from "clsx";
import React from "react";
import styles from "./index.module.scss";
import { API_URL } from "../../../../shared/constants";

export default function Print({ contentRef, apartment }) {
  const order = apartment?.orders?.[0];
  if (!apartment || !order) {
    return "";
  }

  return (
    <div
      ref={contentRef}
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        zIndex: -12,
        background: "white",
        padding: "50px",
        pointerEvents: "none",
      }}
    >
      <h1
        style={{
          textAlign: "center",
          marginBottom: 20,
        }}
      >
        {apartment?.catalog?.name} {apartment.catalog.size}m<sup>2</sup>
      </h1>
      <div
        className={styles.datas}
        style={{
          width: "100%",
        }}
      >
        <div className={clsx(styles.card)}>
          <h2>Xonadon ma'lumotlari </h2>
          <Space className={styles.extrapadding}>
            <b>O'lcham:</b>
            <p>
              {apartment.catalog.size} m<sup>2</sup>
            </p>
          </Space>
          <br />
          <Space className={styles.extrapadding}>
            <b>Xonalar soni:</b>
            <p>{apartment.catalog.room_count} ta</p>
          </Space>
          <br />
          <Space className={styles.extrapadding}>
            <b>Qavat:</b>
            <p>{apartment.floor}</p>
          </Space>{" "}
          <br />{" "}
          <Space>
            <b>Kelishuv holati:</b>
            <p>
              {{
                sold: "Sotildi",
                reserved: "Bron qilindi",
                not_full_paid: "To'liq to'lanmadi",
              }[order?.type] || "Bo'sh"}
            </p>
          </Space>
          <br />
          <br />
        </div>

        <div className={clsx(styles.card)}>
          <h2>Sotuv ma'lumotlari</h2>
          <Space className={styles.extrapadding}>
            <b>Mijoz ismi:</b>
            <p>{order.customerName}</p>
          </Space>{" "}
          <br />
          <Space className={styles.extrapadding}>
            <b>Mijoz raqami:</b>
            <p>+{order.customerPhone}</p>
          </Space>
          <br />
          <Space className={styles.extrapadding}>
            <b>Sotilgan narxi:</b>
            <p>
              {order.price} {{ uzs: "So'm", usd: "$" }[order.priceCurrency]}
            </p>
          </Space>{" "}
          <br /> <b>Qo'shimcha ma'lumotlar:</b>
          <p>{order.description} </p>
          <br />
          <br />
          <br />
        </div>
      </div>
      <div className={styles.image}>
        <img
          src={`${API_URL}/apartment_images/${apartment?.catalog.image_url}`}
          alt=""
          style={{
            width: "100%",
          }}
        />
      </div>
    </div>
  );
}
