import { render } from "@testing-library/react";
import { Button, Popconfirm, Space } from "antd";
import { CiEdit } from "react-icons/ci";
import { FaRegTrashAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import {
  ExclamationCircleTwoTone,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import translate from "./../../../i18n/translate";
import { uz } from 'date-fns/locale';
import { format } from 'date-fns';

export const getColumns = () => {
  const noColumn = {
    title: "NO",
    dataIndex: "no",
    key: "no",
    width: 50,
  };

  return {
    user: [
      // noColumn,
      {
        title: "Foydalanuvchi nomi",
        dataIndex: "username",
        key: "username",
      },

      {
        title: "Role",
        dataIndex: "role",
        key: "role",
      },
      {
        title: "Telefon",
        dataIndex: "phone",
        key: "phone",
      },
      {
        title: "Holati",
        dataIndex: "status",
        key: "status",
      },
    ],
    action: [
      // noColumn,
      {
        title: "Foydalanuvchi nomi",
        dataIndex: "user",
        key: "user",
        render: (user) => user && user.username,
      },

      {
        title: "Amal turi",
        dataIndex: "actionType",
        key: "actionType",
      },
      {
        title: "Target",
        dataIndex: "targetType",
        key: "targetType",
      },
      {
        title: "Bajarilgan vaqti",
        dataIndex: "timestamp",
        key: "timestamp",
        render: (time) => format(time, "d MMMM yyyy, HH:mm:ss", { locale: uz }),
      },
    ],
  };
};

export const getActions = ({ idName = "id", page, handleDelete }) => ({
  title: "Actions",
  dataIndex: idName,
  key: "link",
  width: 100,
  fixed: "right",
  render(id, data) {
    return (
      <div>
        <Space>
          {page && (
            <Link to={`/admin/${page}/${id}`}>
              <Button>
                <CiEdit />
              </Button>
            </Link>
          )}

          <Popconfirm
            title={translate("common.delete")}
            description={translate("confirm.delete")}
            onConfirm={() => handleDelete(id, data)}
            okText={translate("common.yes")}
            cancelText={translate("common.no")}
            placement="topLeft"
            icon={<QuestionCircleOutlined style={{ color: "red" }} />}
          >
            <Button type="primary" danger>
              <FaRegTrashAlt />
            </Button>
          </Popconfirm>
        </Space>
      </div>
    );
  },
});
