import React, { useEffect } from "react";
import { Button, Flex, Spin } from "antd";
import "./index.styles.scss";
import { useUrlSearchParams } from "use-url-search-params";
import ApratmentModel from "./Models/ApratmentModel";
import { useQuery } from "react-query";
import jwtAxios from "../../../auth/jwt-api";
import Loading from "../../../layouts/components/Loading";
import Schema85 from "./SVG/Schema85";
import Schema81 from "./SVG/Schema81";
import Schema82 from "./SVG/Schema82";
import Schema83 from "./SVG/Schema83";
import Schema84 from "./SVG/Schema84";
import Schema91 from "./SVG/Schema91";
import Schema92 from "./SVG/Schema92";
import Schema93 from "./SVG/Schema93";
import Schema94 from "./SVG/Schema94";
import Schema95 from "./SVG/Schema95";
import { GrFormNext } from "react-icons/gr";
import { GrFormPrevious } from "react-icons/gr";
import NumberRangeSelector from "./filters/SizeRange";
import RoomCount from "./filters/RoomCount";
import { useLocation } from "react-router-dom";

const SVGS = {
  "2-8": {
    1: Schema81,
    2: Schema82,
    3: Schema83,
    4: Schema84,
    all: Schema85,
  },
  "9-10": {
    1: Schema91,
    2: Schema92,
    3: Schema93,
    4: Schema94,
    all: Schema95,
  },
};
const blocks = ["1", "2", "3", "4", "all"];
export default function Schema() {
  const [searchParams, setSearchParams] = useUrlSearchParams({
    floor: 2,
    block: "all",
    minArea: 20,
    maxArea: 150,
    roomCount: "1,2,3,4,5",
  });
  const location = useLocation();

  const {
    data: apartments,
    isLoading,
    refetch: refetchFloor,
  } = useQuery(
    ["apartments", "floor", searchParams.floor],
    () =>
      jwtAxios.get(`apartment`, {
        params: {
          limit: 100,
          floor: searchParams.floor,
        },
      }),
    {
      onSuccess(apartments) {
        const results = {};
        apartments?.data?.data?.results?.rows?.map((apartment) => {
          results[`${apartment.block}_${apartment.numberInBlock}`] = apartment;
        });
        apartments.data = results;
      },
    }
  );

  useEffect(() => {
    const apartmentEls = document.getElementsByClassName("apartment");

    Array.from(apartmentEls).forEach((el) => {
      const number = el.getAttribute("block_number");
      if (!apartments?.data[number]) {
        return;
      }
      el.classList.remove("sold", "reserved", "not_full_paid", "active");

      if (apartments.data[number].orders?.[0]?.type) {
        el.classList.add(apartments.data[number].orders[0].type);
      }

      const isActive =
        +searchParams.minArea < apartments.data[number].catalog.size &&
        +searchParams.maxArea > apartments.data[number].catalog.size &&
        searchParams.roomCount?.split(",").length &&
        searchParams.roomCount
          .split(",")
          .includes(String(apartments.data[number].catalog.room_count));

      if (isActive) {
        el.classList.add("active");
      }
    });
  }, [searchParams, apartments]);

  const onApartmentClick = (e) => {
    const block_number = e.target.getAttribute("block_number");
    const apartment = apartments?.data?.[block_number];
    if (apartment) {
      setSearchParams({ ...searchParams, apartmentId: apartment.id });
    }
  };

  const SVG =
    SVGS[+searchParams.floor <= 8 ? "2-8" : "9-10"][
      +searchParams.block || "all"
    ];

  return (
    <div className="schema-container">
      <ApratmentModel
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        refetchFloor={refetchFloor}
      ></ApratmentModel>
      {/* <h1 className="title">Sxema</h1> */}

      <div className="filter-image">
        <div className="image">
          {isLoading && <Spin className="loading"></Spin>}
          <button
            className="prev"
            onClick={() => {
              const nextBlock = blocks[blocks.indexOf(searchParams.block) - 1];
              setSearchParams({
                ...searchParams,
                block: nextBlock ? nextBlock : "all",
              });
            }}
          >
            <GrFormPrevious />
          </button>
          <button
            className="next"
            onClick={() => {
              const nextBlock = blocks[blocks.indexOf(searchParams.block) + 1];
              setSearchParams({
                ...searchParams,
                block: nextBlock ? nextBlock : "1",
              });
            }}
          >
            <GrFormNext />
          </button>
          <SVG
            apartments={apartments?.data}
            onApartmentClick={onApartmentClick}
          ></SVG>
        </div>

        <div className="filter-container">
          <br />
          <h1>Filterlar</h1>
          <div className="filter-card">
            <p>Qavatlar: {searchParams.floor}-qavat </p>
            <div className="buttons">
              {[2, 3, 4, 5, 6, 7, 8, 9, 10].map((floor) => (
                <Button
                  type="primary"
                  ghost={floor == searchParams.floor}
                  onClick={() => setSearchParams({ ...searchParams, floor })}
                >
                  {floor}-qavat
                </Button>
              ))}
            </div>
          </div>
          <div className="filter-card">
            <p>
              Bloklar:{" "}
              {searchParams.block === "all"
                ? "Barcha bloklar"
                : `${searchParams.block}-blok`}
            </p>
            <div className="buttons">
              {[1, 2, 3, 4].map((block) => (
                <Button
                  type="primary"
                  ghost={block == searchParams.block}
                  onClick={() => setSearchParams({ ...searchParams, block })}
                >
                  {block}-blok
                </Button>
              ))}
              <Button
                type="primary"
                ghost={searchParams.block === "all"}
                onClick={() =>
                  setSearchParams({ ...searchParams, block: "all" })
                }
              >
                Barcha bloklar
              </Button>
            </div>
          </div>
          <NumberRangeSelector
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />{" "}
          <RoomCount
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
          <Flex justify="end">
            <Button
              onClick={() =>
                setSearchParams({
                  ...searchParams,
                  minArea: 20,
                  maxArea: 150,
                  roomCount: "1,2,3,4,5",
                })
              }
            >
              Tozalash
            </Button>
          </Flex>
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
      </div>
    </div>
  );
}
